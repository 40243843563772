.cta-button {
    position: fixed;
    bottom: 20px; /* Khoảng cách từ cạnh dưới */
    right: 20px; /* Khoảng cách từ cạnh phải */
    background-color: #007bff; /* Màu nền */
    color: white; /* Màu chữ */
    padding: 15px 15px; /* Kích thước nút */
    border-radius: 50px; /* Làm nút tròn */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Tạo bóng */
    font-size: 16px; /* Kích thước chữ */
    font-weight: bold; /* Đậm chữ */
    text-decoration: none; /* Bỏ gạch dưới */
    z-index: 1000; /* Giữ nút trên cùng */
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn 0.5s ease-in-out;
  }

  /* Hiệu ứng hover */
  .cta-button:hover {
    background-color: #0056b3;
    color: wheat;
    cursor: pointer;
  }

  /* Icon nhỏ nếu cần */
  .cta-icon {
    margin: 0 4px;
    font-size: 20px;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }