/* ----- 24. SUB-BANNER ----- */
.rv-4-sub-banner {

    >.container {
        background-color: #5F60B9;
        padding: 50px 60px;
        position: relative;

        @include xl {
            padding: 50px;
        }

        @include md {
            padding: 40px;
        }

        @include sm {
            padding: 30px 25px;
        }

        @include xxs {
            padding: 20px;
        }
    }

    &__title {
        font-family: var(--sahitya);
        font-size: 160px;
        font-weight: 700;
        text-transform: uppercase;
        color: var(--white-f);
        line-height: 68%;
        margin-bottom: 55px;
        position: relative;

        @include xl {
            font-size: 140px;
        }

        @include lg {
            font-size: 120px;
        }

        @include md {
            font-size: 70px;
            margin-bottom: 40px;
        }

        @include sm {
            font-size: 45px;
            margin-bottom: 30px;
        }

        @include xs {
            font-size: 35px;
            margin-bottom: 30px;
        }

        @include xxs {
            margin-bottom: 20px;
        }

        .styled {
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke: 1px var(--white-f);
            margin-left: 145px;
            position: absolute;
            bottom: -98px;
            overflow: hidden;

            @include md {
                bottom: 0;
                margin-left: 0;
            }
        }
    }

    &__actions {
        .rv-1-socials {
            @include xxs {
                gap: 6px;
            }
        }

        a {
            @include xxs {
                font-size: 14px;
                width: 30px;
                height: 30px;
                line-height: 30px;
            }

            &:hover {
                background-color: #a90e20;
                border-color: #a90e20;
            }
        }

        a.email {
            border: none;
            margin-left: 14px;
            text-decoration: underline;
            text-underline-offset: 4px;
            width: auto;

            @include xxs {
                margin-left: 0;
            }

            &:hover {
                background: none;
                color: var(--white-f);
            }
        }
    }

    &__img-1 {
        position: absolute;
        bottom: 0;
        left: 450px;

        @include xl {
            left: 400px;
        }

        @include md {
            left: auto;
            right: 0;
            width: 190px;
        }

        @include sm {
            width: 160px;
        }

        @include xxs {
            display: none;
        }
    }

    &__img-2 img {
        width: 220px;
        height: 160px;
        transform: rotate(15deg);
        border: 5px solid var(--white-f);
        position: absolute;
        top: 0;
        right: 10px;

        @include xs {
            display: none;
        }
    }
}

// INDEX-7 SUB-BANNER
.rv-7-sub-banner {
    background: url(../../img/rv-7-sub-banner-bg-1.jpg) no-repeat center center;
    background-size: cover;
    display: flex;
    align-items: center;
    padding-block: 54px;
    padding-inline: 50px 20px;
    color: var(--white-f);
    height: 100%;

    @include lg {
        padding-block: 34px;
        padding-inline: 20px;
    }

    @include md {
        flex-direction: column;
        row-gap: 20px;
    }

    @include xs {
        flex-direction: row;
    }

    @include xxs {
        flex-direction: column;
    }

    &--2 {
        background-image: url(../../img/rv-7-sub-banner-bg-2.jpg);
    }

    .rv-7-section__sub-title {
        font-size: 14px;
        color: var(--white-f);
        margin-bottom: 5px;
    }

    &__title {
        color: var(--white-f);
        font-family: var(--mulish);
        font-size: 30px;
        font-weight: 700;
        line-height: 120%;
        width: 85%;
        margin-bottom: 28px;

        @include xl {
            width: 100%;
        }

        @include lg {
            margin-bottom: 23px;
            font-size: 24px;
        }

        @include sm {
            margin-bottom: 18px;
            font-size: 22px;
        }
    }

    .rv-7-def-btn {
        height: 35px;
        line-height: 34px;
        color: var(--black);
        padding: 0 30px;
        text-transform: capitalize;

        &:hover {
            background-color: #4962fb;
            color: var(--white-f);
        }
    }
}

// INDEX-13 SUB-BANNER
.rv-13-sub-banner {
    background-image: url(../../img/rv-13-sub-banner-bg-1.jpg);

    .rv-7-sub-banner__img {
        flex-shrink: 0;
    }

    .rv-7-def-btn {
        background-color: transparent;
        border-radius: 0;
        color: var(--white-f);
        border: 1px solid rgb(255 255 255 / 24%);
        font-family: var(--mulish);
        font-weight: 600;
        line-height: 32px;

        &:hover {
            background-color: #ab8e66;
            border-color: #ab8e66;
        }
    }

    &-2 {
        background-image: url(../../img/rv-13-sub-banner-bg-2.jpg);
    }

    &-3 {
        background-image: url(../../img/rv-13-sub-banner-bg-3.jpg);
        position: relative;
        display: block;
        padding: 80px 80px 85px;

        @include lg {
            padding: 60px 50px 65px;
        }

        @include md {
            padding: 50px 40px 55px;
        }

        @include xxs {
            padding: 40px 25px 45px;
        }

        .rv-7-section__sub-title {
            font-size: 18px;
            margin-bottom: 12px;
        }

        .rv-7-sub-banner__title {
            font-size: 54px;
            line-height: 111.111%;
            margin-bottom: 40px;

            @include lg {
                font-size: 45px;
            }

            @include md {
                font-size: 40px;
            }

            @include sm {
                font-size: 35px;
                margin-bottom: 30px;
            }

            @include xxs {
                font-size: 28px;
                margin-bottom: 20px;
            }
        }

        .rv-7-def-btn {
            background-color: #ab8e66;
            border-color: #ab8e66;

            &:hover {
                background-color: transparent;
            }
        }

        .rv-7-sub-banner__img {
            .title {
                position: absolute;
                top: 35px;
                right: 42px;
                text-transform: uppercase;
                color: rgba(255, 255, 255, 0.24);
                font-family: var(--open_sans);
                font-size: 120px;
                font-weight: 400;
                line-height: 1;

                @include md {
                    top: 73%;
                }

                @include sm {
                    font-size: 80px;
                }
            }

            img {
                position: absolute;
                right: 0;
                top: 8px;
                max-width: 668px;

                @include xl {
                    max-width: 626px;
                }

                @include lg {
                    max-width: 486px;
                }

                @include md {
                    position: relative;
                    max-width: 100%;
                }
            }
        }
    }
}

.rv-13-weekly-deals__countdown {
    position: absolute;
    left: 50%;
    translate: -50% 0;
    bottom: 0;

    .syotimer {
        &__body {
            display: flex;
            justify-content: center;
            background-color: var(--white-f);
            color: var(--black);
            border: 1px solid rgb(5 5 5 / 20%);
        }

        &-cell {
            text-align: center;
            padding: 4px 15px 6px 16px;
            color: var(--black);
            position: relative;

            &:not(:last-child) {
                &::before {
                    content: ":";
                    position: absolute;
                    right: 0;
                    top: 50%;
                    translate: 0 -50%;
                }
            }

            &__value {
                font-family: var(--mulish);
                font-size: 20px;
                font-weight: 700;
                line-height: normal;
            }

            &__unit {
                opacity: 60%;
                font-size: 10px;
                font-weight: 600;
                line-height: normal;
                text-transform: uppercase;
            }
        }
    }

}

// INDEX-21 OFFER SECTION
.rv-21-offer_section {
    padding-top: 80px;

    @media #{$md,$sm,$mobile,$sm-mobile} {
        padding-top: 50px;
    }

    @media #{$sm,$mobile,$sm-mobile} {
        padding-top: 30px;
    }

    .rv-21-single_offer_section {
        display: flex;
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: cover;
        height: 300px;
        overflow: hidden;

        @media #{$md,$sm,$mobile,$sm-mobile} {
            margin-bottom: 30px;
            height: 270px;
        }

        @media #{$sm-mobile} {
            margin-bottom: 30px;
            height: max-content;
            flex-direction: column-reverse;
        }

        .rv-21-single_offer_section_content_left,
        .rv-21-single_offer_section_content_right {
            padding: 40px 0 50px 40px;
            flex: 0 0 65%;

            @media #{$sm-mobile} {
                padding: 30px 0 30px 30px;
            }

            h3 {
                font-family: $mulish;
                font-size: 40px;
                font-weight: 700;
                color: #0E1428;
                margin-bottom: 20px;

                @media #{$xl,$lg,$md,$sm,$mobile,$sm-mobile} {
                    font-size: 30px;
                }

                @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                    font-size: 25px;
                }

                @media #{$mobile,$sm-mobile} {
                    font-size: 22px;
                }

                @media #{$sm-mobile} {
                    font-size: 20px;
                }
            }

            h4 {
                font-family: $mulish;
                font-size: 24px;
                font-weight: 700;
                color: #0E1428;
            }

            .rv-21-single_product_price {
                display: flex;
                align-items: center;
                column-gap: 15px;
                flex-wrap: wrap;

                p {
                    font-family: $mulish;
                    font-size: 20px;
                    font-weight: 500;
                    color: #0E1428;
                    margin: 0;
                }

                span {
                    font-family: $mulish;
                    font-weight: 400;
                    font-size: 14px;
                    color: #0E1428;
                    text-decoration: line-through;
                }
            }

            .rv-21-offer_button_area {
                width: 180px;
                height: 60px;
                position: relative;
                cursor: pointer;
                left: -30px;

                @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                    margin-top: 30px;
                }

                &:hover .rv-21-offer_content_btn {
                    color: #0E1428;
                    letter-spacing: 1px;
                }

                img {
                    height: 100%;
                    width: 100%;
                }

                .rv-21-offer_content_btn {
                    position: absolute;
                    top: 30%;
                    left: 26%;
                    color: #FFFFFF;
                    font-size: 16px;
                    font-weight: 700;
                    transition: all 0.4s ease;
                    font-family: $mulish;
                }
            }

            .rv7_offer_btn_2 {
                left: 0;
                margin-top: 50px;

                @media #{$sm-mobile} {
                    margin-top: 30px;
                }
            }
        }

        .rv-21-single_offer_section_image {
            width: 370px;
            height: auto;

            @media #{$sm-mobile} {
                width: 100%;
                height: 200px;
            }

            img {
                height: 100%;
                width: 100%;
            }
        }

    }
}

// INDEX-21 ACCESSORIES
.rv-21-accessories_main_section {
    padding-top: 100px;

    @media #{$xl,$lg,$md,$sm,$mobile,$sm-mobile} {
        padding-top: 80px;
    }

    @media #{$md,$sm,$mobile,$sm-mobile} {
        padding-top: 60px;
    }

    @media #{$sm,$mobile,$sm-mobile} {
        padding-top: 60px;
    }

    @media #{$sm-mobile} {
        padding-top: 30px;
    }

    .rv-21-accessories {
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: cover;
        padding: 0 50px;

        .rv-21-accessories_section_heading {
            @media #{$sm,$mobile,$sm-mobile} {
                text-align: center;
                padding: 30px 0;
            }

            .rv-21-accessories_sub_title {
                font-family: $mulish;
                color: rgba(255, 255, 255, 0.7);
                font-size: 15px;
                font-weight: 600;
            }

            .rv-21-accessories_section_title {
                font-family: $mulish;
                font-size: 30px;
                font-weight: 700;
                color: #FFFFFF;

                @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                    font-size: 22px;
                }

                @media #{$md,$sm,$mobile,$sm-mobile} {
                    font-size: 15px;
                }
            }

        }

        .rv-21-accessories_slide_iamge {
            text-align: center;
            position: relative;
            top: -26px;
            height: 230px;

            @media #{$md,$sm,$mobile,$sm-mobile} {
                top: -20px;
                height: 179px;
                width: 200px;
            }

            @media #{$sm,$mobile,$sm-mobile} {
                top: -20px;
                height: 179px;
                width: 200px;
                margin: 0 auto;
            }

            img {
                height: 100%;
                widows: 100%;
            }

            span.rv-21-accessories_sh_1 {
                position: absolute;
                left: 10px;
                top: 40px;

                @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                    left: -81px;
                    top: 40px;
                }

                @media #{$md,$sm,$mobile,$sm-mobile} {
                    left: 0;
                    top: 40px;
                }

                img {
                    height: 100%;
                    width: 100%;
                }
            }

            .rv-21-accessories_sh_2 {
                position: absolute;
                bottom: -50px;
                height: 111px;
                width: 215px;
                right: 0;

                @media #{$md,$sm,$mobile,$sm-mobile} {
                    bottom: -40px;
                    height: 105px;
                    width: 170px;
                }

                img {
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .rv-21-accessories_button_area {
            width: 156px;
            height: 180px;
            position: relative;
            cursor: pointer;

            @media #{$lg,$md,$sm,$mobile,$sm-mobile} {
                margin-left: auto;
            }

            @media #{$sm,$mobile,$sm-mobile} {
                margin: 0 auto;
            }

            &:hover .rv-21-accessories_content_btn {
                letter-spacing: 1px;
            }

            img {
                height: 100%;
                width: 100%;
            }

            .rv-21-accessories_content_btn {
                position: absolute;
                top: 44%;
                left: 28%;
                color: #0E1428;
                font-size: 16px;
                font-weight: 700;
                transition: all 0.4s ease;
            }
        }
    }
}